import React, { Component } from "react";
import PropTypes from "prop-types";
import { graphql } from "@apollo/client/react/hoc";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";

import LoadingIndicator from "../general-components/loading-indicator";
import ContentBase from "../general-components/content-base/content-base";
import eventPageQuery from "./event.graphql";
import PagerFullPage from "../general-components/pager-full-page";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { i18nActionHelper } from "../i18n/i18n";
import Image from "../general-components/image/image";
import moment from "moment";
import {FormattedMessage} from "react-intl";
import { Participants } from "./participants";
import {Ticket} from "./ticket";
import {AddToCalendar} from "./add-to-calendar";
import {EventType} from "./event-type";
import EventSeries from "./event-series";
import {ClassCooperation} from "./class-cooperation";
import {Streamlink} from "./streamlink";
import {Location} from "./location";
import Error404 from "../error-404";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{pagerConfig: ([]|*[])}} - Relevant Data for App Component from
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  pagerConfig: reduxStore.appStore.pagerFullPages,
});

class Event extends Component {
  state = {
    queryStrings: queryString.parse(this.props.location.search),
  };

  componentDidMount() {
    this.setState({
      queryStrings: queryString.parse(this.props.location.search),
    });

    i18nActionHelper(this.props, this.props.dispatch);
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.location) !== JSON.stringify(this.props.location)
    ) {
      this.setState({
        queryStrings: queryString.parse(this.props.location.search),
      });
    }

    if (
      JSON.stringify(this.props.data.route) !==
      JSON.stringify(prevProps.data.route)
    ) {
      i18nActionHelper(this.props, this.props.dispatch);
    }
  }

  render() {

    if (!this.props.data.loading && this.props.data.route === null) {
      return <Error404/>;
    }

    if (this.props.data.route) {
      const eventPassed = moment().diff(moment.unix(this.props.data.route.entity.fieldDate.value)) > 0;

      const published = moment(this.props.data.route.entity.status)
        ? "node-published"
        : "node-unpublished";

      return (
        <article
          className={`node-${this.props.data.route.entity.entityBundle} node-${this.props.data.route.entity.entityId} node-full-page ${published} archive-${eventPassed}`}
        >
          <Helmet>
            <title>{`HMDK Stuttgart | ${this.props.data.route.entity.entityLabel}`}</title>
          </Helmet>

          <div className="container">
            <div className="row">
              {eventPassed &&
                <div className="col-16 col-lg-13 offset-lg-3">
                  <div className={"archive-info"}>
                    Archiv
                  </div>
                </div>
              }
              {this.props.data.route.entity.fieldBild && (
                <div className="teaser-image col-16 offset-lg-4 col-lg-8">
                  <Image
                    data={this.props.data.route.entity.fieldBild.entity.fieldMediaImage}
                    nodeTitle={this.props.data.route.entity.title}
                  />
                </div>
              )}
              <div className={"col-16 col-lg-10 offset-lg-3"}>
                <div className="meta-wrapper">
                  {(this.props.data.route.entity.fieldPremiere || this.props.data.route.entity.fieldDerniere) &&
                    <div className="premiere-derniere">
                      {this.props.data.route.entity.fieldPremiere &&
                        <FormattedMessage id="event.premiere" />
                      }
                      {this.props.data.route.entity.fieldDerniere &&
                        <FormattedMessage id="event.derniere" />
                      }
                    </div>
                  }
                  <div className="date">
                    <span>
                      {moment
                        .unix(this.props.data.route.entity.fieldDate.value)
                        .local()
                        .format("dd")}
                    </span>
                    <span>
                      {moment
                        .unix(this.props.data.route.entity.fieldDate.value)
                        .local()
                        .format("DD. MMM,")}
                    </span>
                    {moment(this.props.data.route.entity.fieldDate.value).format("HH:mm") !== "00:00" &&
                      <span>
                        {moment
                          .unix(this.props.data.route.entity.fieldDate.value)
                          .local()
                          .format("H:mm")}
                        {this.props.data.route.entity.fieldDate.endValue && (this.props.data.route.entity.fieldDate.value !== this.props.data.route.entity.fieldDate.endValue) &&
                          <>
                            {" – "}
                            {moment.unix(this.props.data.route.entity.fieldDate.value).local().format("DD.MM.YYYY") !== moment.unix(this.props.data.route.entity.fieldDate.endValue).local().format("DD.MM.YYYY") &&
                              <>
                                {moment
                                  .unix(this.props.data.route.entity.fieldDate.endValue)
                                  .local()
                                  .format("DD.MM.")
                                }
                                {" "}
                              </>
                             }
                            {moment
                              .unix(this.props.data.route.entity.fieldDate.endValue)
                              .local()
                              .format("HH:mm")
                            }
                          </>
                        }
                         <span className="uhr">
                           Uhr
                         </span>
                      </span>
                    }
                  </div>
                  {this.props.data.route.entity.fieldOrt &&
                    <div className="location">{this.props.data.route.entity.fieldOrt}</div>
                  }
                </div>
                {this.props.data.route.entity.title &&
                  <h1>
                    {this.props.data.route.entity.fieldOmitted &&
                      <FormattedMessage id={"event.omitted"}/>
                    }
                    {this.props.data.route.entity.title}
                  </h1>
                }
                {this.props.data.route.entity.fieldTitle2 &&
                  <div
                    className="subline"
                    dangerouslySetInnerHTML={{
                      __html: this.props.data.route.entity.fieldTitle2
                    }}
                  />
                }
                {this.props.data.route.entity.fieldSubtitle &&
                  <div
                    className="subline"
                    dangerouslySetInnerHTML={{
                      __html: this.props.data.route.entity.fieldSubtitle
                    }}
                  />
                }
                {this.props.data.route.entity.fieldTitleExtension &&
                  <div
                    className="subline"
                    dangerouslySetInnerHTML={{
                      __html: this.props.data.route.entity.fieldTitleExtension
                    }}
                  />
                }

                <ClassCooperation content={this.props.data.route.entity}/>

                {this.props.data.route.entity.fieldEventCategory && this.props.data.route.entity.fieldEventCategory.length >= 1 &&
                  <div className="tag-wrapper">
                    {this.props.data.route.entity.fieldEventCategory.map((item, index) => (
                      <React.Fragment key={index}>
                        {item.entity !== null && (
                          <span className="tag">{item.entity.name}</span>
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                }

                {this.props.data.route.entity.body &&
                  <div
                    className="text full"
                    dangerouslySetInnerHTML={{
                      __html: this.props.data.route.entity.body.processed
                    }}
                  />
                }

                <div className={"info-block"}>
                  <div className="container">
                    <div className="row">
                      <div className="col-16 col-lg-8 main-wrapper">
                        <div className="cta-wrapper">
                          <EventType content={this.props.data.route.entity}/>
                          <Streamlink content={this.props.data.route.entity}/>
                          {!eventPassed &&
                            <AddToCalendar content={this.props.data.route.entity} />
                          }
                        </div>
                        {(this.props.data.route.entity.fieldLocation || this.props.data.route.entity.fieldBuilding) &&
                          <div className="address">
                            {this.props.data.route.entity.fieldBuilding &&
                              <div className="building">
                                {this.props.data.route.entity.fieldBuilding}
                              </div>
                            }
                            <Location content={this.props.data.route.entity}/>
                          </div>
                        }
                        {!eventPassed &&
                          <Ticket content={this.props.data.route.entity}/>
                        }
                      </div>
                      {this.props.data.route.entity.fieldParticipating &&
                        <div className="col-16 col-lg-8 participating-wrapper">
                          <Participants content={this.props.data.route.entity}/>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {this.props.data.route.entity.fieldModules &&
            <ContentBase
              content={this.props.data.route.entity.fieldModules}
              nodeContent={this.props.data.route.entity}
            />
          }

          {this.props.data.route.entity.fieldEventSeries && !eventPassed &&
            <EventSeries content={this.props.data.route.entity}/>
          }

          {this.state.queryStrings.p &&
            this.props.pagerConfig.filter(
              (config) => config.id === this.state.queryStrings.p
            ).length > 0 && (
              <PagerFullPage
                config={
                  this.props.pagerConfig.filter(
                    (config) => config.id === this.state.queryStrings.p
                  )[0]
                }
                currentId={this.props.data.route.entity.entityId}
              />
            )}
        </article>
      );
    }

    return <LoadingIndicator fullPage={true}/>;
  }
}

Event.propTypes = {
  data: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  pagerConfig: PropTypes.object,
};

export default graphql(eventPageQuery, {
  options: (props) => ({
    variables: { path: props.location.pathname },
  }),
})(connect(mapStateToProps)(withRouter(Event)));
