import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import TeaserBase from "../../../teaser-base/teaser-base";
import ErrorBoundary from "../../../../error-boundary";
import { teaserPersonPropTypes } from "../../../teaser-base/person/teaser-person";

class ParagraphKontakt extends Component {
  state = {};

  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-kontakt": true,
    });

    return (
      <section className={sectionClassNames}>
        <div className="container">
          <div className="row">
            <div className="col-16 col-xl-12 offset-xl-2">
              <div className="row">
                {this.props.content.fieldPersonen.map((item, index) => {
                  if (item?.entity?.status) {
                    return (
                      <div
                        key={index}
                        className={classNames({
                          "col-14 offset-1  col-lg-4 offset-lg-1": true
                        })}
                      >
                        <ErrorBoundary>
                          <TeaserBase
                            showImage={this.props.content.fieldBilderZeigen}
                            item={item.entity}
                            viewMode={"kontakt"}
                          />
                        </ErrorBoundary>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ParagraphKontakt.propTypes = {
  content: PropTypes.shape({
    __typename: PropTypes.string,
    entityId: PropTypes.string,
    entityBundle: PropTypes.string,
    fieldBilderZeigen: PropTypes.bool,
    fieldPersonen: PropTypes.arrayOf(
      PropTypes.shape({
        targetId: PropTypes.number,
        __typename: PropTypes.string,
        entity: teaserPersonPropTypes,
      })
    ),
  }),
};

export default ParagraphKontakt;
