import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Link from "../../../link/link";

import Image from "../../../image/image";
import {encodeUrl} from "../../../../lib/encode-url";
import HeadlineSelectableSize from "../../../headline-selectable-size";

class ParagraphTeaserImage extends Component {
  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-teaser-image": true,
      "cropped-image": this.props.content.fieldCropImage,
      "standard-image": !this.props.content.fieldCropImage
    });

    return (
      <section className={sectionClassNames}>
        <div className="container fluid">
          <div
            className={classNames({
              row: true,
              "flex-row-reverse":
                this.props.content.fieldImagePosition === "right",
            })}
          >
            <div
              className={classNames({
                "col-16 col-lg-8 image-col": true,
                "offset-lg-1":
                  this.props.content.fieldImagePosition === "right",
              })}
            >
              <Image
                data={this.props.content.fieldImage.entity.fieldMediaImage}
                nodeTitle={this.props.content.fieldHeading}
                showStandardImageStyle={!this.props.content.fieldCropImage}
              />
            </div>
            <div
              className={classNames({
                "col-16 col-lg-6 text-col offset-lg-1": true
              })}
            >
              <div>
                <HeadlineSelectableSize
                  content={this.props.content.fieldHeading}
                  size={this.props.content.fieldRenderHeadlineAs}
                  customClassNames={false}
                />
                {this.props.content.fieldText && (
                  <div
                    className="text"
                    dangerouslySetInnerHTML={{
                      __html: this.props.content.fieldText.processed,
                    }}
                  />
                )}
              </div>
              {this.props.content.fieldCallToAction && (
                <Link
                  className="btn btn-more btn-m"
                  link={this.props.content.fieldCallToAction}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ParagraphTeaserImage.propTypes = {
  content: PropTypes.shape({
    __typename: PropTypes.string,
    entityBundle: PropTypes.string,
    entityId: PropTypes.string,
    fieldCropImage: PropTypes.bool,
    fieldRenderHeadlineAs: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6"]),
    fieldHeading: PropTypes.string.isRequired,
    fieldSetAnchorLink: PropTypes.bool,
    fieldColorscheme: PropTypes.shape({
      __typename: PropTypes.string,
      entity: PropTypes.shape({
        fieldColorVariable: PropTypes.string,
        name: PropTypes.string,
        __typename: PropTypes.string,
      }),
    }),
    fieldImage: PropTypes.shape({
      __typename: PropTypes.string,
      entity: PropTypes.shape({
        __typename: PropTypes.string,
        fieldMediaImage: PropTypes.shape({
          __typename: PropTypes.string,
          alt: PropTypes.string,
          standard: PropTypes.shape({
            __typename: PropTypes.string,
            url: PropTypes.string,
          }),
          title: PropTypes.string,
          style: PropTypes.shape({
            url: PropTypes.string.isRequired,
          }),
        }),
      }),
    }),
    fieldCallToAction: PropTypes.shape({
      __typename: PropTypes.string,
      title: PropTypes.string,
      url: PropTypes.shape({
        __typename: PropTypes.string,
        path: PropTypes.string,
        routed: PropTypes.bool,
      }),
    }),
    fieldText: PropTypes.shape({
      __typename: PropTypes.string,
      value: PropTypes.string,
      processed: PropTypes.string,
    }),
    fieldImagePosition: PropTypes.oneOf(["left", "right"]),
  }),
};

export default ParagraphTeaserImage;
