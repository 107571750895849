import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Slider from "react-slick";
import { ArrowRight32, ArrowLeft32 } from "@carbon/icons-react";
import Marquee from "react-fast-marquee";

import {Link, withRouter} from "react-router-dom";
import Image from "../../../image/image";
import ErrorBoundary from "../../../../error-boundary";

import { Textfit } from 'react-textfit';
import {connect} from "react-redux";

const mapStateToProps = (reduxStore) => ({
  currentLanguage: reduxStore.i18n.currentLanguage,
  microSite: reduxStore.appStore.microSite,
  isMobile: reduxStore.appStore.isMobile
});

class ParagraphTeaserSlider extends Component {
  state = {
    fontFitted: false,
    playMarquee: false,
    sliderSettings: {
      arrows: false,
      dots: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      touchMove: true,
    },
  };

  onTextFitted = () => {
    if (!this.state.fontFitted) {
      this.setState({
        fontFitted: true
      });
    }
  }

  toggleMarquee = (status) => {
    this.setState({
      playMarquee: status
    });
  }

  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-teaser-slider": true,
    });

    return (
      <section className={sectionClassNames}>
        <ErrorBoundary>
          <Slider {...this.state.sliderSettings}>
            {this.props.content.fieldTeaserSlides.map((item, index) => (
              <div className="inner-slide" key={index}>
                <ErrorBoundary>
                  {item.entity.fieldImageOrVideo?.entity.fieldMediaImage &&
                    <Image
                      className="background-media"
                      data={item.entity.fieldImageOrVideo.entity.fieldMediaImage}
                    />
                  }
                  {item.entity.fieldImageOrVideo?.entity.fieldMediaVideoFile &&
                    <video
                      className="background-media"
                      src={item.entity.fieldImageOrVideo.entity.fieldMediaVideoFile.entity.url}
                      playsInline={true}
                      loop={true}
                      muted={true}
                      autoPlay={true}
                      controls={false}
                    />
                  }
                </ErrorBoundary>
                <div className="slide-content">
                  <article className="container">
                    <div className="row">
                      <div className="col-16 position-static">
                        {item.entity.fieldTeaserueberschrift &&
                          <h3 className={this.state.fontFitted ? 'fitted' : 'unfitted'}>
                            {this.props.isMobile ? (
                              <>
                                {item.entity.fieldTeaserueberschrift}
                              </>
                              ) : (
                              <Textfit mode="single" max={400} onReady={this.onTextFitted}>
                                {item.entity.fieldTeaserueberschrift}
                              </Textfit>
                            )}
                          </h3>
                        }
                      </div>
                    </div>
                  </article>
                </div>
                {item.entity.fieldText &&
                  <div
                    className="text-wrapper"
                    onMouseEnter={() => this.toggleMarquee(true)}
                    onMouseLeave={() => this.toggleMarquee(false)}
                  >
                    <Marquee play={this.state.playMarquee} speed={50} gradient={false}>
                      <div
                        className="teaser text"
                        dangerouslySetInnerHTML={{
                          __html: item.entity.fieldText.processed,
                        }}
                      />
                    </Marquee>
                  </div>
                }
              </div>
            ))}
          </Slider>
        </ErrorBoundary>
      </section>
    );
  }
}

ParagraphTeaserSlider.propTypes = {
  content: PropTypes.shape({
    fieldTeaserSlides: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          fieldTeaserueberschrift: PropTypes.string.isRequired,
          fieldImageOrVideo: PropTypes.shape({
            entity: PropTypes.shape({
              fieldMediaImage: PropTypes.shape({
                alt: PropTypes.string,
                title: PropTypes.string,
                style: PropTypes.shape({
                  url: PropTypes.string.isRequired,
                }),
              }),
            }),
          }),
          fieldText: PropTypes.shape({
            processed: PropTypes.string,
          }),
          fieldInhaltLink: PropTypes.shape({
            title: PropTypes.string,
            url: PropTypes.shape({
              routed: PropTypes.bool,
              path: PropTypes.string,
              entity: PropTypes.shape({
                title: PropTypes.string
              })
            })
          }),
        }),
      })
    ),
  }),
};

export default connect(mapStateToProps)(ParagraphTeaserSlider);
