import React from "react";
import {encodeUrl} from "../lib/encode-url";
import CustomHyphenation from "./custom-hyphenation";



export const HeadlineSelectableSize = ({ content, size, customClassNames }) => {
  const id = encodeUrl(content),
  className = `headline ${customClassNames}`;

  const HyphenatedContent = ({ content }) => {
    return (
      <CustomHyphenation length={10}>
        {content}
      </CustomHyphenation>
    );
  }

  const headlineProps = {
    id,
    className
  }

  if (size === "h1") {
    return(
      <h1 {...headlineProps}>
        <HyphenatedContent content={content}/>
      </h1>
    );
  }

  if (size === "h2") {
    return(
      <h2 {...headlineProps}>
        <HyphenatedContent content={content}/>
      </h2>
    );
  }

  if (!size || size === "h3") {
    return(
      <h3 {...headlineProps}>
        <HyphenatedContent content={content}/>
      </h3>
    );
  }

  if (size === "h4") {
    return(
      <h4 {...headlineProps}>
        <HyphenatedContent content={content}/>
      </h4>
    );
  }

  if (size === "h5") {
    return(
      <h5 {...headlineProps}>
        <HyphenatedContent content={content}/>
      </h5>
    );
  }

  if (size === "h6") {
    return(
      <h6 {...headlineProps}>
        <HyphenatedContent content={content}/>
      </h6>
    );
  }
};

export default HeadlineSelectableSize;
