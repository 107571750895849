import React, { Component, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  PlayFilledAlt32,
  PauseFilled32,
  Rewind_1032,
  VolumeUp32,
  VolumeMute32,
  VolumeDown32,
} from "@carbon/icons-react";
import Image from "../../../image/image";

const ParagraphAudio = (props) => {
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(60);

  const player = useRef();

  const timeUpdateListener = (e) => {
    setCurrentTime(e.target.currentTime);
    setDuration(e.target.duration);
  };

  const getTime = (time) => {
    if (!isNaN(time)) {
      return (
        Math.floor(time / 60) + ":" + ("0" + Math.floor(time % 60)).slice(-2)
      );
    }

    return null;
  };

  const goBack = (seconds) => {
    player.currentTime = state.currentTime - seconds;
  };

  const play = () => {
    player.current.play();
  };

  const pause = () => {
    player.current.pause();
  };

  const decreaseVolume = () => {
    player.volume = player.current.volume - 0.2;
  };

  const increaseVolume = () => {
    player.current.volume = player.current.volume + 0.2;
  };

  const mute = () => {
    player.current.volume = 0.0;
  };

  useEffect(() => {
    // event listener if currentTime changed
    player.current.addEventListener("timeupdate", (e) => timeUpdateListener(e));

    // update time display when metadata has loaded
    player.current.addEventListener("loadedmetadata", (e) =>
      timeUpdateListener(e)
    );

    // remove event listeners on unmount
    return () => {
      if (player.current) {
        player.current.removeEventListener("timeupdate", (e) =>
          timeUpdateListener(e)
        );
        player.current.removeEventListener("loadedmetadata", (e) =>
          timeUpdateListener(e)
        );
      }
    };
  }, [player]);

  const sectionClassNames = classNames({
      "paragraph paragraph-audio": true,
    }),
    { content } = props;

  return (
    <section className={sectionClassNames}>
      <div className="container">
        <div className="row">
          <div className="col-16 col-md-12 offset-md-2">
            <div className="audio-wrapper">
              {content.fieldAudioDatei.entity.fieldTeaserImage && (
                <div className="image-section">
                  <Image
                    data={content.fieldAudioDatei.entity.fieldTeaserImage.entity.fieldMediaImage}
                    nodeTitle={content.fieldAudioDatei.entity.fieldTextKopf ? content.fieldAudioDatei.entity.fieldTextKopf : false}
                  />
                </div>
              )}
              <div className="audio-section">
                <div className="text-wrapper">
                  {content.fieldAudioDatei.entity.fieldTextKopf && (
                    <h3>{content.fieldAudioDatei.entity.fieldTextKopf}</h3>
                  )}
                  {content.fieldAudioDatei.entity.fieldTextAudio && (
                    <div
                      className="text"
                      dangerouslySetInnerHTML={{
                        __html:
                          content.fieldAudioDatei.entity.fieldTextAudio
                            .processed,
                      }}
                    />
                  )}
                </div>
                <div className="audio-controls-wrapper">
                  <audio
                    src={
                      content.fieldAudioDatei.entity.fieldMediaAudioFile.entity
                        .url
                    }
                    aria-controls="audio-controls"
                    ref={player}
                  />
                  <div className="time">
                    <div className="current">{getTime(currentTime)}</div>
                    <span>&#8201;/&#8201;</span>
                    <div className="duration">{getTime(duration)}</div>
                  </div>
                  <div className="progress-bar">
                    <div className="bar">
                      <div
                        className="fill"
                        style={{
                          width: `${(100 / duration) * currentTime}%`,
                        }}
                      />
                    </div>
                  </div>
                  <div className="audio-controls">
                    {player.current?.paused ? (
                      <a className="btn btn-m" onClick={play}>
                        PLAY
                      </a>
                    ) : (
                      <a className="btn btn-m" onClick={pause}>
                        PAUSE
                      </a>
                    )}
                  </div>
                  {/*<div id="audio-controls" className="button">
                  <div className="function">
                    <button tabIndex={0} onClick={this.play}>
                      <PlayFilledAlt32 />
                    </button>
                    <button tabIndex={0} onClick={this.pause}>
                      <PauseFilled32 />
                    </button>
                    <button tabIndex={0} onClick={() => this.goBack(10)}>
                      <Rewind_1032 />
                    </button>
                  </div>
                  <div className="volume">
                    <button tabIndex={0} onClick={this.increaseVolume}>
                      <VolumeUp32 />
                    </button>
                    <button tabIndex={0} onClick={this.decreaseVolume}>
                      <VolumeDown32 />
                    </button>
                    <button tabIndex={0} onClick={this.mute}>
                      <VolumeMute32 />
                    </button>
                  </div>
                </div>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphAudio.propTypes = {
  content: PropTypes.shape({
    __typename: PropTypes.string,
    entityBundle: PropTypes.string,
    entityId: PropTypes.string,
    fieldTitleAudio: PropTypes.string,
    fieldColorScheme: PropTypes.shape({
      __typename: PropTypes.string,
      entity: PropTypes.shape({
        __typename: PropTypes.string,
        name: PropTypes.string,
        fieldColorVariable: PropTypes.string,
      })
    }),
    fieldAudioDatei: PropTypes.shape({
      __typename: PropTypes.string,
      entity: PropTypes.shape({
        fieldTextAudio: PropTypes.shape({
          processed: PropTypes.string,
          __typename: PropTypes.string,
          value: PropTypes.string,
        }),
        fieldTextKopf: PropTypes.string,
        fieldMediaAudioFile: PropTypes.shape({
          __typename: PropTypes.string,
          entity: PropTypes.shape({
            __typename: PropTypes.string,
            url: PropTypes.string.isRequired,
          }),
        }),
        fieldTeaserImage: PropTypes.shape({
          customCaption: PropTypes.string,
          defaultCaption: PropTypes.string,
          __typename: PropTypes.string,
          entity: PropTypes.shape({
            __typename: PropTypes.string,
            fieldMediaImage: PropTypes.shape({
              alt: PropTypes.string,
              title: PropTypes.string,
              style: PropTypes.shape({
                url: PropTypes.string,
              }),
            }),
          })
        }),
      }),
    }),
  }),
};

export default ParagraphAudio;
