import React from "react";

const SocialMediaPill = ({ content }) => {

  return(
    <div className="social-media-pill">
      {content.fieldSocialMediaTitle &&
        <div className="social-media-title">
          {content.fieldSocialMediaTitle}
        </div>
      }
      {content.fieldSocialMediaLinks &&
        <div className="social-media-links">
          {content.fieldSocialMediaLinks.map((link, index) =>
            <a
              href={link?.url?.path}
              className="btn btn-primary social-media-link"
              key={index}
            >
              {link.title}
            </a>
          )}
        </div>
      }
    </div>
  );
}

export default SocialMediaPill;