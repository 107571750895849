import React from "react";
import TeaserEvent from "../../../../teaser-base/event/teaser-event";

const EventListBasic = ({ nodes }) => {
  return (
    <div className={"event-list-basic"}>
      {nodes.map((item, index) => (
        <div
          className={"event-wrapper"}
          key={index}
          data-highlighted={item.fieldHighlighted}
        >
          <TeaserEvent item={item?.entity || item} />
        </div>
      ))}
    </div>
  );
}


EventListBasic.propTypes = {};

export default EventListBasic;
