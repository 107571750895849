import React, { Component } from "react";
import PropTypes from "prop-types";
import Image from "../../image/image";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {Link} from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import {self} from "../../../config";

import EditButton from "../../../backend/edit-button";
import {Ticket} from "../../../event/ticket";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
});

/**
 * @todo use "FieldTime"
 */
class TeaserEvent extends Component {
  static defaultProps = { pagerFullPage: false };

  render() {
    if (this.props.item) {
      return (
        <article
          className={`node node-${this.props.item.entityId} ${this.props.item.fieldBild ? 'with-image' : 'without-image'} node-teaser teaser-event-calendar-detail`}
        >
          <EditButton
            adminApp={this.props.adminApp}
            entityId={this.props.item.entityId}
            destinationRoute={this.props.location.pathname}
          />
          {this.props.item.fieldBild && (
            <div className="teaser-image">
              <Image
                data={this.props.item.fieldBild.entity.fieldMediaImage}
                nodeTitle={this.props.item.title}
              />
            </div>
          )}
          <div className="content-wrapper">
            <div className="top-wrapper">
              {this.props.item.fieldSchlagwort.length >= 1 &&
                <div className="tag-wrapper">
                  {this.props.item.fieldSchlagwort.map((item, index) => (
                    <React.Fragment key={index}>
                      {item.entity !== null && (
                        <span className="tag">{item.entity.name}</span>
                      )}
                    </React.Fragment>
                  ))}
                </div>
              }
              <h3>
                {this.props.item.fieldOmitted &&
                  <FormattedMessage id={"event.omitted"}/>
                }
                {this.props.item.title}
              </h3>
              {this.props.item.fieldTitle2 &&
                <div
                  className="subtitle"
                  dangerouslySetInnerHTML={{
                    __html: this.props.item.fieldTitle2
                  }}
                />
              }
              {this.props.item.fieldSubtitle &&
                <div
                  className="subtitle"
                  dangerouslySetInnerHTML={{
                    __html: this.props.item.fieldSubtitle
                  }}
                />
              }
              {this.props.item.fieldText &&
                <div
                  className="text"
                  dangerouslySetInnerHTML={{
                    __html: this.props.item.fieldText.processed
                  }}
                />
              }
            </div>
            <div className="bottom-wrapper">
              <div className="left-col">
                {(this.props.item.fieldModules.length > 1 || this.props.item.fieldBuilding || this.props.item.fieldAddress || this.props.item.fieldChoreography || this.props.item.fieldMuisc || this.props.item.fieldDirector || this.props.item.fieldVoice) &&
                  <div className="show-more">
                    <Link to={this.props.item.path.alias}>
                      <FormattedMessage id={"more"}/>
                      <img src={`${self}/plus.svg`} alt={"more infos"}/>
                    </Link>
                  </div>
                }
              </div>
              <div className="right-col">
                <Ticket content={this.props.item} />
              </div>
            </div>
          </div>
        </article>
      );
    }
  }
}

export const teaserEventPropTypes = PropTypes.shape({
  entityId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  path: PropTypes.shape({
    alias: PropTypes.string,
  }),
  entityUrl: PropTypes.shape({
    path: PropTypes.string,
  }),
  fieldOrt: PropTypes.string,
  fieldDauerAusblenden: PropTypes.bool,
  fieldText: PropTypes.shape({
    processed: PropTypes.string.isRequired,
  }),
  fieldBild: PropTypes.shape({
    entity: PropTypes.shape({
      fieldMediaImage: PropTypes.shape({
        alt: PropTypes.string,
        title: PropTypes.string,
        style: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    }),
  }),
  fieldDate: PropTypes.shape({
    value: PropTypes.string,
    endTime: PropTypes.string,
  }),
  fieldSchlagwort: PropTypes.arrayOf(
    PropTypes.shape({
      entity: PropTypes.shape({
        name: PropTypes.string,
      }),
    })
  ),
  fieldTicketlink: PropTypes.shape({
    url: PropTypes.shape({
      path: PropTypes.string,
    }),
  }),
});

TeaserEvent.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  item: teaserEventPropTypes,
  location: PropTypes.object.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
};

export default connect(mapStateToProps)(withRouter(injectIntl(TeaserEvent)));
