import React, { Component } from "react";
import PropTypes from "prop-types";

import ParagraphHeroText from "./paragraphs/hero-text/paragraph-hero-text";
import ContentCloud from "./paragraphs/content-cloud/paragraph-content-cloud";
import ParagraphTextAndSpeech from "./paragraphs/text-and-speech/paragraph-text-and-speech";
import ParagraphClickSuggestions from "./paragraphs/click-suggestions/paragraph-click-suggestions";
import ParagraphTeaserList from "./paragraphs/teaserliste/paragraph-teaser-list";
import ParagraphTeaserImage from "./paragraphs/teaser-image/paragraph-teaser-image";
import ParagraphSectionheading from "./paragraphs/section-heading/paragraph-sectionheading";
import ParagraphText from "./paragraphs/text/paragraph-text";
import Eforkbot from "./../../eforkbot/eforkbot";
import ParagraphBilderSlider from "./paragraphs/image-image-slider/paragraph-bilder-slider";
import ParagraphNumberFacts from "./paragraphs/number-facts/paragraph-number-facts";
import ParagraphTeaserSlider from "./paragraphs/teaser-slider/paragraph-teaser-slider";
import ParagraphNewsletteranmeldung from "./paragraphs/newsletter/paragraph-newsletteranmeldung";
import ParagraphAkkordionText from "./paragraphs/akkordion-text/paragraph-akkordion-text";
import ParagraphDossier from "./paragraphs/dossier/paragraph-dossier";
import ParagraphMedia from "./paragraphs/media-video-audio/paragraph-media";
import ParagraphPageTitle from "./paragraphs/page-title/paragraph-page-title";
import ParagraphCallToAction from "./paragraphs/cta/paragraph-call-to-action";
import ParagraphExtendedTeaserOverview from "./paragraphs/extended-teaser-list/paragraph-extended-teaser-overview";
import ParagraphCalendarInformation from "./paragraphs/kalender-information/paragraph-calendar-information";
import ParagraphDownload from "./paragraphs/download/paragraph-download";
import ParagraphZitat from "./paragraphs/zitat/paragraph-zitat";
import ParagraphQuoteCarousel from "./paragraphs/quote-carousel/paragraph-quote-carousel";
import ParagraphDownloadSchublade from "./paragraphs/download-schublade/paragraph-download-schublade";
import ParagraphTimeline from "./paragraphs/timeline/paragraph-timeline";
import ParagraphKontakt from "./paragraphs/kontakt/paragraph-kontakt";
import ParagraphStrukturierteListe from "./paragraphs/strukturierte-liste/paragraph-strukturierte-liste";
import ParagraphPersonenInformationen from "./paragraphs/personen-informationen/paragraph-personen-informationen";
import ParagraphKalenderEinzelDoppel from "./paragraphs/kalender-einzel-doppel/paragraph-kalender-einzel-doppel";
import ParagraphKalenderAbschnitt from "./paragraphs/kalender-abschnitt/paragraph-kalender-abschnitt";
import ParagraphKalenderMonat from "./paragraphs/kalender-monat/paragraph-kalender-monat";
import ParagraphAudio from "./paragraphs/audio/paragraph-audio";
import ParagraphLogosPartners from "./paragraphs/logos-partners/logos-partners";
import ParagraphTeaserListEvents from "./paragraphs/teaserliste/events/paragraph-teaser-list-events";
import ParagraphFormular from "./paragraphs/webform/paragraph-formular";
import TeeaserOverviewMulti from "./paragraphs/teaser-overview-multi/teaser-overview-multi";
import ParagraphPinboard from "./paragraphs/pinboard/paragraph-pinboard";
import ParagraphArtistOverview from "./paragraphs/artist-overview/paragraph-artist-overview";

import ErrorBoundary from "../../error-boundary";
import ParagraphTeaserListPages from "./paragraphs/teaserliste/pages/paragraph-teaser-list-pages";
import ParagraphExtendedTeaserOverviewProjects from "./paragraphs/extended-teaser-list/projects/paragraph-extended-teaser-overview-projects";
import ParagraphScheduledModule from "./paragraphs/scheduled-module/paragraph-scheduled-module";
import ParagraphBite from "./paragraphs/bite/paragraph-bite";
import ParagraphExtendedTeaserOverviewPersons from "./paragraphs/extended-teaser-list/persons/paragraph-extended-teaser-overview-persons";
import InPageNavigation from "../in-page-navigation/in-page-navigation";

class ContentBase extends Component {
  render() {
    return (
      <section className={`content-wrap ${this.props.className}`}>
        {this.props.content.map((item, index) => (
          <ErrorBoundary key={index}>
            <div
              className={`module-wrapper ${item.entity.entityBundle}`}
              data-color-scheme={
                item.entity.fieldColorScheme
                  ? item.entity.fieldColorScheme.entity.fieldColorVariable
                  : "default"
              }
            >
              {
                // eslint-disable-next-line complexity
                (() => {
                  let paragraphItem = item.entity;

                  if (paragraphItem.entityBundle === "from_library") {
                    paragraphItem =
                      item.entity.fieldReusableParagraph.entity.paragraphs
                        .entity;
                  }

                  switch (paragraphItem.entityBundle) {
                    case "content_cloud":
                      return <ContentCloud content={paragraphItem} />;
                    case "text_and_speech":
                      return <ParagraphTextAndSpeech content={paragraphItem} />;
                    case "audio":
                      return <ParagraphAudio content={paragraphItem} />;
                    case "media":
                      return <ParagraphMedia content={paragraphItem} />;
                    case "hero_text":
                      return <ParagraphHeroText content={paragraphItem} />;
                    case "click_suggestions":
                      return (
                        <ParagraphClickSuggestions content={paragraphItem} />
                      );
                    case "teaserliste":
                    case "teaserliste_news":
                    case "teaserliste_personen":
                    case "teaserlist_artists":
                    case "teaserlist_lectures":
                      return <ParagraphTeaserList content={paragraphItem} />;
                    case "teaserliste_events":
                      return (
                        <ParagraphTeaserListEvents content={paragraphItem} />
                      );
                    case "teaserliste_pages":
                      return (
                        <ParagraphTeaserListPages content={paragraphItem} />
                      );
                    case "teaser_image_text":
                      return <ParagraphTeaserImage content={paragraphItem} />;
                    case "section_heading":
                      return (
                        <ParagraphSectionheading content={paragraphItem} />
                      );
                    case "text":
                      return <ParagraphText content={paragraphItem} />;
                    case "bilder_slider":
                    case "image":
                      return <ParagraphBilderSlider content={paragraphItem} />;
                    case "zahlen_und_fakten":
                      return <ParagraphNumberFacts content={paragraphItem} />;
                    case "teaser_slider":
                      return <ParagraphTeaserSlider content={paragraphItem} />;
                    case "formular":
                      return <ParagraphFormular content={item.entity} />;
                    case "newsletteranmeldung":
                      return (
                        <ParagraphNewsletteranmeldung content={paragraphItem} />
                      );
                    case "text_akkordion":
                      return <ParagraphAkkordionText content={paragraphItem} />;
                    case "chatbot":
                      return <Eforkbot />;
                    case "teaser_sammlung_dossier_":
                      return <ParagraphDossier content={paragraphItem} />;
                    case "seitentitel":
                      return <ParagraphPageTitle content={paragraphItem} />;
                    case "call_to_action":
                      return <ParagraphCallToAction content={paragraphItem} />;
                    case "erweiterte_teaseruebersicht":
                    case "teaseruebersicht_events": {
                      const nodes = {};

                      if (paragraphItem.fieldManuelleSortierung) {
                        nodes.loading = false;
                        nodes.nodeQuery = {};
                        nodes.nodeQuery.entities =
                          paragraphItem.fieldBeitraege.map(
                            (node) => node.entity
                          );

                        return (
                          <ParagraphExtendedTeaserOverview
                            content={paragraphItem}
                            nodes={nodes}
                          />
                        );
                      }

                      return (
                        <ParagraphExtendedTeaserOverview
                          content={paragraphItem}
                        />
                      );
                    }
                    case "teaseruebersicht_personen": {
                      const nodes = {};

                      if (paragraphItem.fieldManuelleSortierung) {
                        nodes.loading = false;
                        nodes.nodeQuery = {};
                        nodes.nodeQuery.entities =
                          paragraphItem.fieldBeitraege.map(
                            (node) => node.entity
                          );

                        return (
                          <ParagraphExtendedTeaserOverviewPersons
                            content={paragraphItem}
                            nodes={nodes}
                          />
                        );
                      }

                      return (
                        <ParagraphExtendedTeaserOverviewPersons
                          content={paragraphItem}
                        />
                      );
                    }
                    case "teaseruebersicht_projekte": {
                      const nodes = {};

                      if (paragraphItem.fieldManuelleSortierung) {
                        nodes.loading = false;
                        nodes.nodeQuery = {};
                        nodes.nodeQuery.entities =
                          paragraphItem.fieldBeitraege.map(
                            (node) => node.entity
                          );

                        return (
                          <ParagraphExtendedTeaserOverviewProjects
                            content={paragraphItem}
                            nodes={nodes}
                          />
                        );
                      }

                      return (
                        <ParagraphExtendedTeaserOverviewProjects
                          content={paragraphItem}
                        />
                      );
                    }
                    /*case "veranstaltungsinformationen":
                      return (
                        <ParagraphCalendarInformation
                          content={paragraphItem}
                          nodeContent={this.props.nodeContent}
                        />
                      );
                    case "personeninformationen":
                      return (
                        <ParagraphPersonenInformationen
                          content={paragraphItem}
                          nodeContent={this.props.nodeContent}
                        />
                      );*/
                    case "download":
                      return (
                        <ParagraphDownload
                          content={paragraphItem}
                          nodeContent={this.props.nodeContent}
                        />
                      );
                    case "zitat":
                      return (
                        <ParagraphZitat
                          content={paragraphItem}
                          nodeContent={this.props.nodeContent}
                        />
                      );
                    case "quote_carousel":
                      return (
                        <ParagraphQuoteCarousel
                          content={paragraphItem}
                          nodeContent={this.props.nodeContent}
                        />
                      );
                    case "download_schublade":
                      return (
                        <ParagraphDownloadSchublade
                          content={paragraphItem}
                          nodeContent={this.props.nodeContent}
                        />
                      );
                    case "timeline":
                      return (
                        <ParagraphTimeline
                          content={paragraphItem}
                          nodeContent={this.props.nodeContent}
                        />
                      );
                    case "kontakt":
                      return (
                        <ParagraphKontakt
                          content={paragraphItem}
                          nodeContent={this.props.nodeContent}
                        />
                      );
                    case "strukturierte_liste":
                      return (
                        <ParagraphStrukturierteListe
                          content={paragraphItem}
                          nodeContent={this.props.nodeContent}
                        />
                      );
                    case "kalender_doppel_einzel_highlight":
                      return (
                        <ParagraphKalenderEinzelDoppel
                          content={paragraphItem}
                        />
                      );
                    case "kalenderabschnitt":
                      return (
                        <ParagraphKalenderAbschnitt content={paragraphItem} />
                      );
                    case "kalendermonatsuebersicht":
                      return <ParagraphKalenderMonat content={paragraphItem} />;
                    case "logos_partners":
                      return <ParagraphLogosPartners content={paragraphItem} />;
                    case "teaser_overview_multi":
                      return <TeeaserOverviewMulti content={paragraphItem} />;
                    case "pinboard":
                      return <ParagraphPinboard content={paragraphItem} />;
                    case "scheduled_module":
                      return (
                        <ParagraphScheduledModule content={paragraphItem} />
                      );
                    case "bite":
                      return <ParagraphBite content={paragraphItem} />;
                    case "artist_overview":
                      return <ParagraphArtistOverview content={paragraphItem} />;
                    case "in_page_navigation":
                      return <InPageNavigation modules={this.props.content} />;
                    default:
                      return null;
                  }
                })()
              }
            </div>
          </ErrorBoundary>
        ))}
      </section>
    );
  }
}

ContentBase.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      entity: PropTypes.shape({
        fieldReusableParagraph: PropTypes.object,
        entityBundle: PropTypes.string.isRequired,
        fieldManuelleSortierung: PropTypes.bool,
      }),
    })
  ),
  className: PropTypes.string,
  nodeContent: PropTypes.object,
};

export default ContentBase;
