import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Slider from "react-slick";
import {self} from '../../../../config';
import GalleryArrow from "../../../icons/gallery-arrow";

class ParagraphNumberFacts extends Component {
  numberFacts = React.createRef();

  render() {
    const slidesToShow = 3.33,
      slidesToShowMobile = 1.25;

    const sectionClassNames = classNames({
      "paragraph paragraph-number-facts": true,
    }),
    sliderSettings = {
      arrows: true,
      nextArrow: <GalleryArrow type={"next"} followMouse={false}/>,
      prevArrow: <GalleryArrow type={"prev"} followMouse={false}/>,
      dots: false,
      slidesToShow,
      slidesToScroll: 1,
      infinite: true,
      center: true,
      touchMove: true,
      variableWidth: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: slidesToShowMobile,
            touchMove: true,
            variableWidth: false,
            center: false
          },
        }
      ],
    };

    return (
      <section className={sectionClassNames}>
        <div className="container">
          <div className="row">
          </div>
        </div>
        <div className={"number-fact-wrapper"} ref={this.numberFacts}>
          <Slider {...sliderSettings}>
            {this.props.content.fieldZahlenUndFakten?.map((item, index) => (
              <div
                className={`number-fact ${item.entity.fieldDownload ? 'download' : 'fact'} ${item.entity.fieldZahl.length > 80 ? 'long-text' : 'normal'}`}
                key={index}
              >
                <div className="inner-wrapper">
                  <div className="number">
                    {item.entity.fieldZahl}
                    {item.entity.fieldDownload &&
                      <a
                        href={item.entity.fieldDownload.entity.fieldMediaFile.entity.url}
                        download={true}
                        target={"_blank"}
                      >
                      <span className="sr-only">
                        download
                      </span>
                        <img src={`${self}/download.svg`} className="download-icon" alt={"download icon"}/>
                      </a>
                    }
                  </div>
                  <div className="label">
                    {item.entity.fieldLabel}
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </section>
    );
  }
}

ParagraphNumberFacts.propTypes = {
  content: PropTypes.shape({
    fieldZahlenUndFakten: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          fieldZahl: PropTypes.string.isRequired,
          fieldLabel: PropTypes.string.isRequired,
        }),
      })
    ),
  }),
};

export default ParagraphNumberFacts;
