import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";

import { encodeUrl } from "../../../../lib/encode-url";

const NewsletterRegistration = ({ content }) => {
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");

  const onSubmit = (values, actions) => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
      setSuccess(true);
      setSuccessMsg("Sie wurden in den Newsletter eingetragen.");
      actions.setSubmitting(false);
    }, 750);
  };

  const sectionClassNames = classNames({
    "paragraph paragraph-newsletter-register": true,
  });

  return (
    <div className="newsletter-register-block">
      {/* @todo: Messages as overlay? */}
      <div className="message-block">
        {error && !errorMsg && (
          <div
            className="alert alert-danger"
            role="region"
            aria-live="polite"
          >
            Es ist ein Fehler aufgetreten.
          </div>
        )}

        {error && errorMsg && (
          <div
            className="alert alert-danger"
            role="region"
            aria-live="polite"
          >
            {errorMsg}
          </div>
        )}

        {success && successMsg && (
          <div
            className="alert alert-success"
            role="region"
            aria-live="polite"
          >
            {successMsg}
          </div>
        )}
      </div>

      <h3 id={encodeUrl(content.fieldHeading)}>{content.fieldHeading}</h3>

      <Formik
        onSubmit={onSubmit}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={{
          email: "",
          consent: false,
        }}
        validationSchema={Yup.lazy(() =>
          Yup.object().shape({
            email: Yup.string().email().required("E-Mail is required"),
            consent: Yup.bool().oneOf(
              [true],
              "Sie müssen den Bedingungen zustimmen."
            ),
          })
        )}
      >
        {({ errors, touched }) => (
          <Form noValidate>
            <div className="form-row">
              <div className="col-16 form-group">
                <label
                  htmlFor="email"
                  className="sr-only"
                >
                  Ihre E-Mail-Adresse
                </label>
                <Field
                  aria-label="Ihre E-Mail-Adresse"
                  type="email"
                  name="email"
                  id="email"
                  className="form-control"
                  placeholder="E-Mail"
                />
                {errors.email && touched.email ? (
                  <div
                    className="form-error text-danger"
                    role="region"
                    aria-live="polite"
                  >
                    {errors.email}
                  </div>
                ) : null}
              </div>

              <div className="col-16 form-check">
                <Field
                  type="checkbox"
                  name="consent"
                  id="consent"
                  aria-labelledby="form-check-label"
                  className="form-check-input"
                />
                <label className="form-check-label" htmlFor="consent">
                  Ja, ich bin einverstanden. Dieses Einverständnis kann
                  ich jederzeit widerrufen.
                  {errors.consent && touched.consent ? (
                    <span
                      className="form-error text-danger"
                      role="region"
                      aria-live="polite"
                    >
                      {errors.consent}
                    </span>
                  ) : null}
                </label>
              </div>
            </div>

            <div className="form-row actions">
              <div className="col-16 d-flex justify-content-end">
                <button type="submit" className="btn btn-primary">
                  Newsletter bestellen
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

NewsletterRegistration.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string,
    __typename: PropTypes.string,
    entityBundle: PropTypes.string,
    entityId: PropTypes.string,
    fieldText: PropTypes.shape({
      __typename: PropTypes.string,
      value: PropTypes.string,
      processed: PropTypes.string,
    }),
  }),
};

export default NewsletterRegistration;
