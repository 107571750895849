import React from "react";
import {FormattedMessage} from "react-intl";
import {restHostBackend} from "../config";
import {Link} from "react-router-dom";

export const InternalInfo = (props) => {
  return(
    <div className="internal-info">
      <div className={"title"}>
        <FormattedMessage id="search.internal_info_title" />
      </div>
      <div className={"text"}>
        <FormattedMessage id="search.internal_info_text" />
      </div>
      <Link to={"/login"} className="btn">
        Login
      </Link>
    </div>
  );
};

export default InternalInfo;