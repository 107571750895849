import React, { Component } from "react";
import PropTypes from "prop-types";
import { graphql } from "@apollo/client/react/hoc";
import { compose } from "recompose";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import menuQuery from "../general-components/menu-query.graphql";
import configQuery from "../config-query.graphql";
import {disableBodyScroll, enableBodyScroll} from "body-scroll-lock";
import LogoButton from "../general-components/logo-button";
import Menu from "./menu";
import MenuCTA from "./menu-cta";
import {encodeUrl} from "../lib/encode-url";
import ShortcutMenu from "./shortcut-menu";
import PeopleLink from "./people-link";
import {FormattedMessage} from "react-intl";
import MenuButton from "./menu-button";
import LanguageSwitch from "../i18n/language-switch";
import Arrow from "../general-components/icons/arrow";
import Search from "../search/search";
import { menuCtaAction } from "../app-actions";
import UserStatusBlock from "../intern/user-status-block";
import {scrollOptions} from "../lib/scroll-options";

const mapStateToProps = (reduxStore) => ({
  currentLanguage: reduxStore.i18n.currentLanguage,
  microSite: reduxStore.appStore.microSite,
  isMobile: reduxStore.appStore.isMobile,
  menuCTAConfig: reduxStore.appStore.menuCTAConfig,
  auth: reduxStore.auth
});

const CloseMenu = (props) => {
  return(
    <MenuButton
      menuOpen={props.state.menuOpen}
      onClick={props.onClick}
      onKeyDown={props.onKeyDown}
      closeOnly={true}
    />
  );
}

/**
 * Close on menu link click.
 */
class SimpleNavigation extends Component {
  state = {
    menuOpen: false,
    openTree: false,
    activeLevel: 1,
    activeLink: [],
    transitionBlocked: false
  };

  clickLevelLink = (newLevel, newActiveLink) => {
    // Save which first Level Link is selected
    let activeFirstLevel = null,
      activeLink = this.state.activeLink;

    // Remove last added item if new link is on same level
    if (newLevel === this.state.activeLevel) {
      activeLink.pop();
    }

    if (newLevel === 2) {
      activeFirstLevel = newActiveLink;
      // Save top level differently and always tart from scratch when changing first level links
      this.setState({
        activeFirstLevel,
        activeLevel: newLevel,
        activeLink: [newActiveLink]
      });
    } else {
      this.setState({
        activeLevel: newLevel,
        activeLink: activeLink ? [...activeLink, newActiveLink] : [newActiveLink]
      });
    }
  }

  toggleFullScreenMenu = () => {
    this.setState({ menuOpen: !this.state.menuOpen }, () => {
      if (this.state.menuOpen) {
        disableBodyScroll(document.getElementById('site-header'), scrollOptions);
      } else {
        enableBodyScroll(document.getElementById('site-header'), scrollOptions);
        // Reset Menu after Animation is finished
        setTimeout(() => {
          this.setState({
            activeLevel: 1,
            activeLink: false
          });
        }, 500);
      }
    });
  };

  shortcutToMenuLink = (linkLabel) => {
    // Temporary deactivate menu transition, because otherwise we have divergent animations
    this.setState({
      transitionBlocked: true
    });

    setTimeout(() => {
      this.setState({
        transitionBlocked: false
      });
    }, 500);

    this.clickLevelLink(2, encodeUrl(linkLabel));
    this.toggleFullScreenMenu();
  }

  handleKeyDown = (event) => {
    if (event.key === "Enter" || event.key === "Escape") {
      this.toggleFullScreenMenu();
    }
  };

  menuGoBack = () => {
    let activeLink = this.state.activeLink;

    activeLink.pop();

    this.setState({
      activeLevel: this.state.activeLevel - 1,
      activeLink: activeLink
    });
  }

  showGoBackButton = (menuLevel) => {
    let condition = this.state.activeLevel > 1 && (this.state.activeLevel) === (menuLevel + 1);

    if (!this.props.isMobile) {
      condition = this.state.activeLevel > 1 && (this.state.activeLevel - 1) === (menuLevel + 1);
    }

    return condition ? "show" : "hide";
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.menuCtaConfig !== prevProps.menuCtaConfig) &&
      (this.props.menuCtaConfig && this.props.menuCtaConfig.configPagesQuery?.entities[0])
    ) {
      this.props.dispatch(menuCtaAction(this.props.menuCtaConfig.configPagesQuery?.entities[0]));
    }
  }

  render() {
    return (
      <>
        <div className={"navigation standard"}>
          <div className="fixed-wrapper">
            <div className="container">
              <div className="row">
                <div className="col-8 col-md-5 col-lg-8 panic-button-wrapper">
                  <LogoButton type={"standard"}/>
                </div>
                <div className="col-8 toggle-wrapper">
                  {!this.props.isMobile &&
                    <>
                      <Search/>
                      <LanguageSwitch/>
                    </>
                  }
                  <MenuButton
                    menuOpen={this.state.menuOpen}
                    onClick={this.toggleFullScreenMenu}
                    onKeyDown={this.handleKeyDown}
                    closeOnly={false}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={"fullscreen-menu-wrapper"}>
            <div
              id="fullscreen-menu"
              className={this.state.menuOpen ? "active" : ""}
            >
              <div className="nav-wrap">
                {this.props.isMobile &&
                  <CloseMenu
                    state={this.state}
                    onClick={this.toggleFullScreenMenu}
                    onKeyDown={this.handleKeyDown}
                  />
                }
                {!this.props.mainMenuQuery.loading &&
                  this.props.mainMenuQuery.menuByName &&
                  <nav className={`mega-menu active-${this.state.activeLevel} ${this.state.transitionBlocked ? 'transition-blocked' : 'transition-standard'}`}>
                    <div className={`menu-level level-cta`}>
                      {this.props.menuCtaConfig && this.props.menuCtaConfig.configPagesQuery?.entities[0] && this.props.menuCtaConfig.configPagesQuery?.entities[0].fieldMenuCtas &&
                        <MenuCTA
                          content={this.props.menuCtaConfig.configPagesQuery.entities[0]}
                        />
                      }
                    </div>
                    {[...Array(3)].map((x, menuLevel) =>
                      <div className={`menu-level level-${menuLevel + 1} level-${menuLevel < this.state.activeLevel ? 'active' : 'inactive'}`} key={menuLevel}>
                        {menuLevel === 0 && this.props.isMobile &&
                          <div className="mobile-wrapper-search-language">
                            <Search/>
                            <LanguageSwitch/>
                          </div>
                        }
                        <div className={`menu-back ${this.showGoBackButton(menuLevel)}`}>
                          <button className={`btn solid`} onClick={() => this.menuGoBack()}>
                            <Arrow orientation={"left"}/>
                            {this.state.activeLevel === 2 ? (
                              <FormattedMessage id="back" />
                            ) : (
                              <>
                                {this.state.activeFirstLevel}
                              </>
                            )}
                          </button>
                        </div>
                        {menuLevel === 0 && !this.props.isMobile &&
                          <CloseMenu
                            state={this.state}
                            onClick={this.toggleFullScreenMenu}
                            onKeyDown={this.handleKeyDown}
                          />
                        }
                        <div className="inner-wrapper">
                          <div className="main-menu">
                            <Menu
                              items={this.props.mainMenuQuery.menuByName.links}
                              level={menuLevel + 1}
                              clickLevelLink={this.clickLevelLink}
                              activeLevel={this.state.activeLevel}
                              activeLink={this.state.activeLink}
                              toggleFullScreenMenu={this.toggleFullScreenMenu}
                            />
                          </div>
                          {menuLevel === 0 &&
                            <>
                              {!this.props.oftenClickedMenuQuery.loading &&
                                this.props.oftenClickedMenuQuery.menuByName && (
                                  <div className="often-clicked">
                                    <nav className="often-clicked">
                                      <ul>
                                        {this.props.oftenClickedMenuQuery.menuByName.links.map(
                                          (item, index) => (
                                            <li
                                              onClick={() => this.toggleFullScreenMenu()}
                                              key={index}
                                            >
                                              <Link to={item.url.path}>{item.label}</Link>
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    </nav>
                                  </div>
                                )}

                              {!this.props.microSite &&
                                <div className={"bottom-menu"}>
                                  <div className="gradient-wrapper">
                                    <PeopleLink
                                      config={this.props.menuCtaConfig.configPagesQuery?.entities[0]}
                                      onClick={this.toggleFullScreenMenu}
                                    />
                                    {this.props.auth.userLoginData.logout_token ? (
                                      <UserStatusBlock />
                                    ) : (
                                      <button className="login">
                                        <Link
                                          to={"/intern/login"}
                                          className="btn"
                                          onClick={this.toggleFullScreenMenu}
                                        >
                                          Login
                                        </Link>
                                      </button>
                                    )}
                                  </div>
                                </div>
                              }
                            </>
                          }
                        </div>
                      </div>
                    )}
                  </nav>
                }
              </div>
            </div>
          </div>
          </div>
        <div className={"navigation extended"}>
          <div className="fixed-wrapper">
            <div className="container">
              <div className="row">
                <div className="col-13 col-md-5 panic-button-wrapper">
                  <LogoButton type={"extended"}/>
                </div>
                {!this.props.mainMenuQuery.loading &&
                  this.props.mainMenuQuery.menuByName &&
                  this.props.menuCtaConfig.configPagesQuery?.entities[0] &&
                  <ShortcutMenu
                    links={this.props.mainMenuQuery.menuByName.links}
                    config={this.props.menuCtaConfig.configPagesQuery?.entities[0]}
                    shortcutToMenuLink={this.shortcutToMenuLink}
                  />
                }
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

SimpleNavigation.propTypes = {
  mainMenuQuery: PropTypes.object,
  oftenClickedMenuQuery: PropTypes.object,
};

export default connect(mapStateToProps)(
  compose(
    graphql(menuQuery, {
      options: (props) => ({
        variables: {
          name: props.microSite === "gdf" ? 'menue-gdf' : props.microSite === "kv" ? 'menue-kv' : props.microSite === "wt" ? 'menue-wt' : 'main',
          language: props.currentLanguage
            ? props.currentLanguage.toUpperCase()
            : "DE",
        },
      }),
      name: "mainMenuQuery",
    }),
    graphql(configQuery, {
      options: {
        variables: { name: "menu_cta" },
      },
      name: "menuCtaConfig",
    }),
    graphql(menuQuery, {
      options: (props) => ({
        variables: {
          name: props.microSite === "gdf" ? 'often-clicked-gdf' : props.microSite === "kv" ? 'often-clicked-kv' : props.microSite === "wt" ? 'often-clicked-wt' : 'haeufig-geklickt',
          language: props.currentLanguage
            ? props.currentLanguage.toUpperCase()
            : "DE",
        },
      }),
      name: "oftenClickedMenuQuery",
    })
  )(SimpleNavigation)
);
