import React, { Component } from "react";
import PropTypes from "prop-types";
import { graphql } from "@apollo/client/react/hoc";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import LoadingIndicator from "../general-components/loading-indicator";
import ContentBase from "../general-components/content-base/content-base";
import NewsQuery from "./news.graphql";
import PagerFullPage from "../general-components/pager-full-page";
import { i18nActionHelper } from "./../i18n/i18n";
import FieldTime from "../general-components/date-time/field-time";
import Image from "../general-components/image/image";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{pagerConfig: ([]|*[])}} - Relevant Data for App Component from
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  pagerFullPageContextId: reduxStore.appStore.pagerFullPageContextId,
  pagerConfig: reduxStore.appStore.pagerFullPages,
  microSite: reduxStore.appStore.microSite,
  microSiteFrontPage: reduxStore.appStore.microSiteFrontPage,
});

class News extends Component {
  componentDidMount() {
    i18nActionHelper(this.props, this.props.dispatch);
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.data.route) !==
      JSON.stringify(prevProps.data.route)
    ) {
      i18nActionHelper(this.props, this.props.dispatch);
    }
  }

  render() {
    if (this.props.data.route) {
      const published = this.props.data.route.entity.status
        ? "node-published"
        : "node-unpublished";

      return (
        <article
          className={`node-${this.props.data.route.entity.entityBundle} node-${this.props.data.route.entity.entityId} node-full-page ${published}`}
        >
          <Helmet>
            <title>{`HMDK Stuttgart | ${this.props.data.route.entity.entityLabel}`}</title>
          </Helmet>

          <div className="news-head">
            <div className="container">
              <div className="row">
                {this.props.data.route.entity.fieldTeaserbild && (
                  <div className="teaser-image col-16 offset-lg-4 col-lg-8 col-xl-8 offset-lg-4">
                    <Image
                      data={this.props.data.route.entity.fieldTeaserbild.entity.fieldMediaImage}
                      nodeTitle={this.props.data.route.entity.title}
                    />
                  </div>
                )}
                {this.props.data.route.entity.entityLabel &&
                  <div className="col-16 col-lg-12 offset-lg-2">
                    <h1>
                      {this.props.data.route.entity.entityLabel}
                    </h1>
                  </div>
                }
                {this.props.data.route.entity.fieldSchlagwort &&
                  <div className="col-16 col-lg-12 offset-lg-2">
                    <div className="tag-wrapper">
                      {this.props.data.route.entity.fieldSchlagwort.map((item, index) => (
                        <React.Fragment key={index}>
                          {item.entity !== null && (
                            <span className="tag s">{item.entity.name}</span>
                          )}
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                }
                {this.props.data.route.entity.publishedAt &&
                  <div className="col-16 col-lg-12 offset-lg-3 date-info">
                    <FieldTime
                      timestamp={true}
                      date={
                        this.props.data.route.entity.publishedAt.value
                          ? this.props.data.route.entity.publishedAt.value
                          : this.props.data.route.entity.publishedAt.publishedAtOrNow
                      }
                      format={"D. MMMM YYYY"}
                    />
                  </div>
                }
                {this.props.data.route.entity.fieldText &&
                  <div className="col-16 col-lg-12 offset-lg-2">
                    <div
                      className="text teaser-text"
                      dangerouslySetInnerHTML={{
                        __html: `${this.props.data.route.entity.fieldText.processed}`,
                      }}
                    />
                  </div>
                }
              </div>
            </div>

          </div>

          {this.props.data.route.entity.fieldModules && (
            <ContentBase content={this.props.data.route.entity.fieldModules} />
          )}

          {this.props.pagerFullPageContextId &&
            this.props.pagerConfig.filter(
              (config) => config.id === this.props.pagerFullPageContextId
            ).length > 0 && (
              <PagerFullPage
                config={
                  this.props.pagerConfig.filter(
                    (config) => config.id === this.props.pagerFullPageContextId
                  )[0]
                }
                currentId={this.props.data.route.entity.entityId}
              />
            )}
        </article>
      );
    }

    return <LoadingIndicator fullPage={true} />;
  }
}

News.propTypes = {
  data: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  pagerConfig: PropTypes.array,
  pagerFullPageContextId: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]).isRequired,
};

export default connect(mapStateToProps)(
  graphql(NewsQuery, {
    options: (props) => ({
      variables: {
        path:
          props.location.pathname === "/" &&
          props.microSite &&
          props.microSiteFrontPage !== ""
            ? props.microSiteFrontPage
            : props.location.pathname,
      },
    }),
  })(withRouter(News))
);