import React from "react";
import PropTypes from "prop-types";
import SliderComponent from "./slider-component";

import Image from "../../../image/image";

/**
 * This get called from ParagraphBilderSlider and actually renders the images.
 */
const ComponentBildSlider = (props) => (
  <>
    {props.content.fieldMultimedia && props.content.fieldMultimedia.length > 0  ? (
      <SliderComponent
        content={props.content.fieldMultimedia}
        globalCaption={props.content.fieldBildunterschrift}
        formatSmall={props.formatSmall}
      />
    ) : (
      <article className="media-image">
        {props.content.fieldSingleImage &&
          <Image
            data={props.content.fieldSingleImage?.entity.fieldMediaImage}
            credit={props.content.fieldSingleImage?.entity.fieldCredit}
            caption={
              props.content.fieldSingleImage?.defaultCaption || props.content.fieldSingleImage?.customCaption
            }
            hideCaption={props.hideCaption}
            globalCaption={props.globalCaption}
            wrapCaption={props.wrapCaption}
          />
        }
      </article>
    )}
  </>
);

ComponentBildSlider.propTypes = {
  media: PropTypes.arrayOf(
    PropTypes.shape({
      defaultCaption: PropTypes.bool,
      customCaption: PropTypes.string,
      entity: PropTypes.shape({
        fieldCredit: PropTypes.string,
        fieldMediaImage: PropTypes.shape({
          alt: PropTypes.string,
          title: PropTypes.string,
          style: PropTypes.shape({
            url: PropTypes.string.isRequired,
          }),
        }),
      }),
    })
  ),
  globalCaption: PropTypes.string,
  wrapCaption: PropTypes.bool,
  formatSmall: PropTypes.bool,
};

export default ComponentBildSlider;
